import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import 'react-table/react-table.css';

import PropTypes from 'prop-types';
import DashboardTemplate from './template';

const CampaignDashboard = ({ data }) => {
  const pageTitle = 'Campaigns Leaderboard';
  const pageDescription = 'Track campaign performance for leads and sales.';
  const summaryData = data.gwxapi.allCampaigns;

  return (
    <DashboardTemplate
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      summaryData={summaryData}
    />
  );
};

CampaignDashboard.propTypes = {
  data: PropTypes.object.isRequired,
};

const CampaignDashboardWithQuery = () => (
  <StaticQuery
    query={graphql`
      query {
        gwxapi {
          allCampaigns {
            name
            leads {
              email
              isConverted
            }
          }
        }
      }
    `}
    render={data => <CampaignDashboard data={data} />}
  />
);

export default CampaignDashboardWithQuery;
